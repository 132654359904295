.navFooter{
    max-width: 80vw;
    margin: auto;
}


.navFooter__description{
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: left;
    color: #323441;
  
}

.bold{
    font-weight: bold;
}

.navFooter__logo{
    margin-bottom: 30px;
}

.description__top{
    margin-bottom: 30px;
}

.description__bottom{
    margin-bottom: 48.4px;
}

.navFooter23__heading{
font-family: Inter;
font-size: 17px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 1.76;
letter-spacing: 0.85px;
text-align: left;
color: #333e49;
margin-bottom: 19px;
}


.navFooter23__subHeadings{
    font-family: Inter;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    text-align: left;
    color: #323441;
    margin-bottom: 15px;
    cursor: pointer;
}


.downloadLinks{
    display: flex;
    flex-direction: column;
}

.downloadLink > img{
    width: 167px;
    height: auto;
}

/* .navFooter__middle{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
} */


@media only screen and (max-device-width: 414px) {
    .navFooter{
        display: none;
    }
}




/* @media(min-width : 992px){
.navFooter__middle{
    margin-left: 25%;
}
.navFooter{
    border-top: solid 1px #d7d7d7;
    border-bottom: solid 1px #d7d7d7;
    padding-top: 58px;
}
.navFooter__logo{
    margin-top: -10px;
}
.navFooter__leftmost{
    border-right: solid 1px #d7d7d7;
}
} */


 
