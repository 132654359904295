.footerMain{
    max-width: 85vw;
    margin: auto;
}


.footerMain__container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.terms{
    flex: 1;
}

.defaultFooter{
    background-color: #ffffff;
}

#youtube{
    width: 40px;
    height: 40px;
}


@media only screen and (max-device-width: 414px) {
    .footerMain{
        display: none;
    }
}
