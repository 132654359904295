@media(min-width: 1200px){
.features{
    margin-top: 109px;
    margin-bottom: 71px;
}
}

.features__heading {
    font-family: Inter;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: normal;
    text-align: left;
    color: #403f4c;
    margin-top: 28px;
    margin-bottom: 10px;
}

.features__subHeading{
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #818181;  
    margin-bottom: 26px;
}

.mark{
    color: #ed217c !important;
}

.features__description{
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: left;
    color: #333e49;
  
}

.features__content{
    margin-top: 26px;
}

.features__ContentRight{
    margin-top: 190px;
    margin-left: 0.16%;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
}


.features__ContentLeft{
    margin-top: 190px;
    /* margin-right: 20px; */
    margin-right: 0.16%;
    /* padding-right: 120px; */
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
}
 
.features__img{
    margin-left: 12%;
}

.mobile-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.phone-mobile{
    display: none;
}

@media (max-width:498px){
    /* .features__row .features__img .phone>img{
        width: 400px;
    } */

    .features__row{
        display: flex;
    }

    .mobile-content{
        order: 2;
    }

    .mobile-img{
        order: 1;
    }

    .features__ContentLeft{
        max-width: 100%;
    }
    .features__ContentRight{
        max-width: 100%;
    }
}

@media only screen and (max-device-width: 480px) {
    .heading-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .features__heading{
        margin-left: 15px;
        font-size: 22px;
        line-height: 1.36;
        letter-spacing: normal;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    /* #products{
        display: flex;
        flex-direction: column;
    } */
    /* #mobile_products{
        order: 1;
    } */
    /* #mobile-content{
        order: 2;
    } */
    
    .phone-mobile{
        display: block;
    }
    .phone{
        display: none;
    }
    .features{
        margin-top: 40px;
    }

    .features__ContentRight{
        margin-top: 22px;
    }
    .features__ContentLeft{
        margin-top: 22px;
    }
    .heading-container > img{
        width: 40px;
        height: fit-content;
    }
    .features__description{
        font-size: 12px;
        line-height: 1.67;
    }
    .features__content{
        margin: 44px 22px;
    }
    .features__img{
        margin-left: 0px;
    }
    .features__img_div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}





