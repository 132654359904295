.appHome{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.appHome__heading{
    text-align: center;
    margin-top: 161px;
}
.heading1{
    /* width: 739px;
    height: 160px; */
    /* margin: 161px 37px; */
    /* 16px 351px; */
    font-family: Inter;
    font-size: 60px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    /* text-align: center; */
    color: #333e49;
    /* position: relative;
    top: 30px; */
}

.heading2{
    /* width: 739px;
    height: 160px; */
    font-family: Inter;
    font-size: 60px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #333e49;
}

.heading3{
    font-weight: bold;
    color: #8171f6;
}

.appDescription{
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;

}

.description{
    font-family: Inter;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    color: #818181;
    margin-top: 20px;
}

@media(min-width:992px){
    .heading1{
        margin-bottom: -15px;
    }
    .description{
    margin-top: -35px;
    }
}

.btnHolder{
    margin-top: 20px;
    margin-bottom: 20px;
}


.btnHolder > Button{
    margin: 30px;
    margin-bottom: 0px !important;
    width: 202px;
    height: 53px;
    padding: 14px 30px 15px;
    border-radius: 8px;
    background-color: #8171f6;
  
}


Button.button1{
    background-color: #ffffff !important;
    color: #8171f6;
}

Button.button1 :hover{
    color: #8171f6;
}

Button.button2{
    border-color: #8171f6;
}

.homeBtn{
    margin: 25px;
}

.img__top1 > img{
    
   position: relative;
   bottom: 40px;
   right: 350px;

}

 .img__top2 > img{
    position: relative;
    bottom: 30px;  
      
    left: 350px;
} 




.img__bottom1 > img{
    position: relative;
    /* top: 35px; */
    right: 300px;
}

.img__bottom2 > img{
    position: relative;
    /* top: 35px; */
    left: 300px;
}
.highlight{
    color: #8171f6;
}


.ant-btn-primary:hover, .ant-btn-primary:focus :hover{
    background-color: #8171f6;
    border-color: #8171f6;
}

.ant-btn:hover{
    border-color: #8171f6;
}

.homeImg-mobile{
    display: none;
}

.btnHolder{
    display: flex;
}

/* .download-mobile{
    display: none;
} */

@media only screen and (max-device-width: 480px){
    .homeImg-mobile{
        display: block;
    }
    .homeImg{
        display: none;
    }
    .btnHolder{
        display: flex;
    }
    /* .navFooter__logo{
        display: none;
    } */
    /* .download-mobile{
        display: block;
    } */
    .description{
        font-size: 12px;
        margin-top: 16px;
    }
    .btnHolder{
        margin-top: 0px;
        /* margin-bottom: 20px; */
    }
    .heading1{
        font-size: 28px;
        font-weight: 500;
        line-height: 37px;
        font-weight: bold;
        margin: 0px 50px;
    }
    .appHome__heading{
        margin-top: 63px;
    }
    .appDescription{
        margin-top: 16px;
        margin-bottom: 18px;
    }
    .navFooter__logo > img{
        width: 117px;
        height: 39px;
    }
    .container-fluid{
        padding: 0px 23px;
    }
}
