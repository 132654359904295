*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;

}

@media(max-width: 700px){
  .app{
  height: 100vh !important;
  width: 100vw !important;
  }
}


