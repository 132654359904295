/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300&display=swap');

@media(min-width: 1200px){
    .clients{
        /* width: 1200px;
        height: 390px; */
        margin: 102px 91px 109px 121px;
        padding: 58px 172px 66px 173px;
        border-radius: 16px;
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        border: solid 1px #eeeeee;
      
    }
    .clients{
        margin-top: 160px;
        margin-bottom: 109px;
    }
}



.clients__heading{
    margin-top: 58px;
    margin: 0 123px 23px 120px;
    font-family: Inter;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #333e49;
}



.clients__description{
    /* margin: 0px 0px 29px 0; */
    font-family: 'Inter';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: center;
    color: #818181;
    margin-bottom: 29px;
}

.clients__logo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@media only screen and (max-width: 414px){
    #client{
        /* margin-top: 40px; */
        margin: 40px 22px;
        padding-top: 25px;
        border-radius: 16px;
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        border: solid 1px #eeeeee;
    }
    .clients__description{
        font-size: 12px;
        padding: 12px;
    }
    .clients__heading{
        font-size: 22px;
        padding-top: 12px;
    }
 
}