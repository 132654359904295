.header{
    display: flex;
    margin-top: 0px;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
}

@media(min-width: 992px){
.logo{
    margin-left: 120px;
}
}

.container-fluid {
    margin: 0 auto;
    max-width: 100%;
    padding: 0 15px;
  
  }

  .ant-menu-horizontal{
    border-bottom: none;
  }

  @media(min-width: 992px){
  .menu{
      margin-right: 140px;
  }
}

  .menuItem{
    /* height: 24px; */
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
  }

  .mobileVisible {
    display: none;
  }
  
  .mobileHidden {
    display: block;
  }
  

  @media only screen and (max-width: 1000px) {
    /* .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
      transform: scale(1);
    } */
  
    .mobileVisible {
      display: block;
    }
    
    .mobileHidden {
      display: none;
    }
  }

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {

    color: #8171f6;
    border-bottom: 2px solid #8171f6;

}

.ant-btn-primary {
  background-color: #8171f6;
}
  
.header__hamburger :hover{
  color: #8171f6;
}

.logo-mobile{
  display: none;
}

/* mobile view  */

@media only screen and (max-width: 600px) and (min-width: 300px) {
  .logo-mobile{
    display: block;
  }
  .logo-desktop{
    display: none;
  }
  
}